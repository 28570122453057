<template>
    <div>
        <page-header pageTitle="COMMISSIONS.INDEX" :filter="!isRealtor()" @filter="setFilterModal"/>
        <div class="card" v-if="showBarAllotment">
            <div class="card-header">
                <div class="card-title">
                    <label>{{ t('NAV.ALLOTMENT') }}</label>
                </div>
            </div>
            <div class="card-body pt-0">
                <Select2 :settings="{ width: '50%', placeholder: 'Selecione um Loteamento' }" id="allotment"
                         name="allotment" v-model="allotment_id"
                         :options="allotments" @select="changeAllotment($event)"/>
            </div>
        </div>
        <tableComponent :titles="title" hasIconExport @exportPdf="exportPdf">
            <template #tableBody>
                <tr v-for="(item, key) in commissions.data" :key="key">
                    <td class="text-center p-1">
                        <div>
                            <p class="m-0">{{ item?.corretor_name }}</p>
                            <div class="color-grey" style="font-size: 11.5px">{{ item?.cpf_cnpj_corretor }}</div>
                        </div>
                    </td>
                    <td class="text-center">{{ item?.comprador }}</td>
                    <td class="text-center">
                        <div class="btn w-100 rounded-pill font-weight-bold p-1 card align-self-center mb-0"
                             :style="[{'background-color' : traductionColor(item?.situacao)},
                                                {'color': 'white'}, {'cursor': 'grab'}]"
                        >{{ traductionStatus(item?.situacao) }}
                        </div>
                    </td>
                    <td class="text-center">{{ item?.quadra }}</td>
                    <td class="text-center">{{ item?.lote }}</td>
                    <td class="text-center">{{ formatterValue(item?.valor_corretagem) }}</td>
                    <td class="text-center">{{ formatterValue(item?.valor_pago_corretagem) }}</td>
                    <td class="text-center">{{ formatterDate(item?.data_movimentacao) }}</td>
                    <td class="text-center">
                        <button class="btn btn-primary"
                                data-target="#customModalTwo" data-toggle="modal"
                                @click="setModalView(item)"
                                title="Visualizar"><i class="icon-eye"></i>
                        </button>
                    </td>
                </tr>
                <td colspan="11" v-if="!commissions?.data?.length">
                    <div class="text-center p-3">
                        <h5>Nenhuma Comissão encontrada</h5>
                    </div>
                </td>
            </template>
        </tableComponent>
        <pagination-component v-if="commissions && commissions.data && commissions.data[0]" :items="commissions"
                              :to="'/comissoes'"
                              @changePage="index($event)"></pagination-component>
        <modal-select-allotment v-if="allotments" :showModal="showAllotmentSelect"
                                @showModal="showAllotmentSelect = $event"
                                @allotmentSelected="allotmentSelected"></modal-select-allotment>
        <modal :titleModal="modalTitle" modal-large v-if="wichModal === 1">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <div>
                <div class="modal-row2 modal-height-max">
                    <div class="modal-body">
                        <div class="row gutters mt-2">
                            <div class="col-12 mb-2">
                                <label>Loteamento: </label>
                                <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Loteamento' }"
                                         name="allotment"
                                         v-model="allotment_id"
                                         disabled
                                         :options="allotments"/>
                            </div>
                            <div class="col-6">
                                <label>Situação: </label>
                                <Select2 :settings="{ width: '100%', placeholder: 'Situação' }"
                                         id="status"
                                         v-model="filter.situacao"
                                         name="status" @select="selectStatus"
                                         :options="[
                                                 {id: 'Nenhum', text: 'Nenhum'},
                                                 {id:'PENDENTE', text: 'Pendente'},
                                                 {id: 'PAGO', text: 'Pago'},
                                                 {id: 'LIBERADO', text: 'Liberado'}
                                         ]"
                                />
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="cpf_cnpj_filter">Corretor: </label>
                                    <input type="text" id="cpf_cnpj_filter" v-model="filter.cpf_cnpj_ou_nome"
                                           placeholder="CPF ou Nome"
                                           class="form-control">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="vencimento_inicial_filter">Vencimento Inicial: </label>
                                    <input type="date" id="vencimento_inicial_filter"
                                           v-model="filter.vencimento_inicial"
                                           class="form-control">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="vencimento_final_filter">Vencimento Final: </label>
                                    <input type="date" id="vencimento_final_filter" v-model="filter.vencimento_final"
                                           class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer right">
                    <button type="button" class="btn btn-secondary mr-1" @click="clearFilter()">Limpar</button>
                    <button type="button" class="btn btn-primary mr-1" @click="search()">{{
                            t('ACTIONS.FILTER')
                        }}
                    </button>
                </div>
            </div>
        </modal>
        <modal :titleModal="modalTitle" modal-xl v-if="wichModal === 2">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <viewComissionComponent :iten-index="commission"></viewComissionComponent>
        </modal>
    </div>
</template>

<script>
import pageHeader from '../../components/layouts/pageHeader';
import tableComponent from '../../components/layouts/tableComponent';
import Commissions from '../../services/Commissions';
import {mapState} from 'vuex';
import modalSelectAllotment from '../allotment/modalSelectAllotmentComponent.vue';
import Select2 from 'vue3-select2-component';
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import moment from 'moment';
import modal from '../../components/modal';
import viewComissionComponent from './viewComissionComponent';
import isReator from '../../mixins/isReator';
import PaginationComponent from '../../components/layouts/PaginationComponent';

export default {
    name: "indexComissionComponent",
    components: {
        pageHeader,
        tableComponent,
        modalSelectAllotment,
        Select2,
        modal,
        viewComissionComponent,
        PaginationComponent,
    },
    computed: {
        ...mapState({
            allotments: 'allotments',
        })
    },
    mixins: [isReator],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    mounted() {
        this.loadAllotmentBd();
    },
    data() {
        return {
            showBarAllotment: false,
            title: ["Corretor", "Comprador", "Situação", "Quadra", "Lote", "Corretagem", "Valor Pago", "Data Movimentação"],
            showAllotmentSelect: false,
            commissions: [],
            commission: {},
            filter: {},
            wichModal: null,
            modalTitle: '',
            allotment_id: null,
        }
    },
    methods: {
        search() {
            document.getElementById('closeX')?.click();
            this.wichModal = null;
            this.index();
        },
        loadAllotmentBd(){
            this.$store.commit('changeLoading', true);
            Commissions.loadAllotmentBd().then((resp) => {
                if (resp.data?.total > 1){
                    this.showBarAllotment = true;
                    let twoAllotment = this.allotments?.length > 1;
                    this.showAllotmentSelect = twoAllotment && (localStorage.getItem('allotmentId') ? false : true);
                    if (this.allotments?.length === 1 && !localStorage.getItem('allotmentId')) {
                        this.$store.commit('changeLoading', true);
                        this.allotment_id = this.allotments.find((i) => i).id
                        localStorage.setItem('allotmentId', this.allotment_id);
                        this.index();
                    } else if (localStorage.getItem('allotmentId')) {
                        this.$store.commit('changeLoading', true);
                        this.allotment_id = localStorage.getItem('allotmentId');
                        this.index();
                    }
                }else{
                    this.showBarAllotment = false;
                    this.$store.commit('changeLoading', true);
                    this.allotment_id = this.allotments.find((i) => i).id
                    localStorage.setItem('allotmentId', this.allotment_id);
                    this.index();
                }
            }).catch(() => {
                this.commissions = [];
                this.$store.commit('changeLoading', false);
            })

        },
        exportPdf(){
            this.$store.commit('changeLoading', true);
            Commissions.index(this.allotment_id, this.filter, 1, true).then((resp) => {
                var base64Data = resp.data;
                var byteCharacters = atob(base64Data.split(',')[1]);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], { type: 'application/pdf' });
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = 'arquivo.pdf';
                document.body.appendChild(a);
                a.click();
                setTimeout(function() {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 0);
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.commissions = [];
                this.$store.commit('changeLoading', false);
            })
        },
        setModalView(iten) {
            this.wichModal = 2;
            this.commission = iten;
            this.modalTitle = 'COMMISSIONS.VIEW';
        },
        clearFilter() {
            document.getElementById('closeX')?.click();
            this.wichModal = null;
            this.filter = {};
            this.index();
        },
        index(page = 1, exportPdf = false) {
            this.$store.commit('changeLoading', true);
            Commissions.index(this.allotment_id, this.filter, page, exportPdf).then((resp) => {
                if (!resp?.data.validations) {
                    this.commissions = resp.data;
                } else {
                    this.commissions = [];
                }
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.commissions = [];
                this.$store.commit('changeLoading', false);
            })
        },
        async setFilterModal() {
            this.$store.commit('changeLoading', true)
            this.wichModal = 1;
            this.modalTitle = 'ACTIONS.FILTER';
            this.$store.commit('changeLoading', false)
        },
        selectStatus({id}) {
            if (id === 'Nenhum') {
                delete this.filter.situacao;
            } else {
                this.filter.situacao = id;
            }
        },
        formatterDate(date) {
            if (!date) {
                return '-'
            }
            return moment(date).format('DD/MM/YYYY');
        },
        formatterValue(value) {
            if (!value) {
                return '-';
            }
            let val = parseFloat(value);
            return val.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2});
        },
        traductionColor(status) {
            if (status === 'PAGO') {
                return '#3FBF3F';
            } else if (status === 'PENDENTE') {
                return '#FB5C12';
            } else if (status === 'LIBERADO') {
                return '#ee8e7d';
            }
        },
        traductionStatus(status) {
            if (status === 'PAGO') {
                return 'Pago';
            } else if (status === 'PENDENTE') {
                return 'Pendente';
            } else if (status === 'LIBERADO') {
                return 'Liberado';
            }
            return '-';
        },
        changeAllotment({id}) {
            this.$store.commit('changeLoading', true);
            localStorage.removeItem('allotmentId');
            localStorage.setItem('allotmentId', id);
            let allotmentSelect = this.allotments.find(item => item.id === id);
            this.allotment_id = allotmentSelect.id;
            this.index();
        },
        allotmentSelected() {
            this.$store.commit('changeLoading', true);
            this.allotment_id = localStorage.getItem("allotmentId");
            this.index();
        },
    }
}
</script>

<style scoped>
.color-grey {
    color: #727272;
}
</style>